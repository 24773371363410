import { Col, Container, Row } from "react-bootstrap";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { io } from "socket.io-client";
import {
  getChatHead,
  getChats,
  getCompanionChats,
  getJobsWithApplicantsInProgress,
  sendMessage,
} from "../../redux/jobSlice/actions";
import "./index.scss";
import { useNavigate, useParams } from "react-router-dom";
import { updateChatHead } from "../../redux/userSlice/actions";

const socket = io("https://kaytreebackend-63da29f5d5cb.herokuapp.com/");
// const socket = io("http://localhost:8000/");

function Chat() {
  let dispatch = useDispatch();
  let [lastOpenChat, setLastOpenChat] = useState(false);
  let [openChats, setOpenChats] = useState([]);
  const navigate = useNavigate();
  let chats = useSelector((state) => state.job.chats);
  let user = useSelector((state) => state.auth.user);
  let chatHeads = useSelector((state) => state.user.chatHeads);

  let { id } = useParams();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    if ("Notification" in window) {
      if (Notification.permission === "granted") {
      } else if (Notification.permission !== "denied") {
        Notification.requestPermission();
      }
    }

    socket.on("chatMessage", (data) => {
      dispatch(getChats(data.project_id)).then((res) => {
        const options = {
          body: data.message,
          icon: require("../../assets/images/logo.png"),
          vibrate: [200, 100, 200],
          tag: "unique-tag",
          renotify: false,
        };

        if (data?.receiver_id === user?._id) {
          dispatch(getChatHead()).then((res) => {
            if (res?.payload?.data) {
              let count = res?.payload?.data?.reduce(
                (a, b) => a.count + b.count
              );
              if (count > 0) {
                document.getElementById("badgeNew").style.display = "flex";
                document.getElementById("badgeNew").innerHTML = count;
              } else {
                document.getElementById("badgeNew").style.display = "none";
              }
            }
          });
        }

        new Notification("You have a new message", options);
      });
      dispatch(getChatHead(data?.project_id));
    });

    return () => {
      socket.off("chatMessage");
    };
  }, [dispatch, user]);

  useEffect(() => {
    if (user?.role === "loved-one") {
      dispatch(getJobsWithApplicantsInProgress()).then((res) => {
        console.log(res);
      });
    } else {
      dispatch(getCompanionChats());
    }
  }, [user]);

  let [text, setText] = useState("");

  const setChat = (a) => {
    if (user.role === "loved-one") {
      dispatch(getChats(a._id)).then((res) => {
        setOpenChats([a]);
        setLastOpenChat(`chat`);
        navigate(`/chat/${a._id}`);
        setTimeout(() => {
          var element = document.getElementById(`message-${a._id}`);
          if (element && element?.scrollHeight) {
            element.scrollTop = element?.scrollHeight;
          }
        }, 500);
      });
    } else {
      dispatch(getChats(a?.job?._id)).then((res) => {
        setOpenChats([a]);
        setLastOpenChat(`chat`);
        navigate(`/chat/${a?.job?._id}`);
        setTimeout(() => {
          var element = document.getElementById(`message-${a?.job?._id}`);
          if (element && element?.scrollHeight) {
            element.scrollTop = element?.scrollHeight;
          }
        }, 500);
      });
    }
  };

  const send = (a, e) => {
    console.log(a);
    e.preventDefault();
    let obj = {};
    if (a) {
      obj.receiver_id =
        user?.role === "loved-one"
          ? a?.applications[0]?.applicant?._id
          : a?.createdBy?._id;
      obj.message = text;
      obj.project_id = user?.role === "loved-one" ? a?._id : a?.job?._id;
      obj.job_id = user?.role === "loved-one" ? a?._id : a?.job?._id;
    }

    dispatch(sendMessage(obj)).then((res) => {
      if (user.role === "loved-one") {
        dispatch(getChats(a._id)).then((res) => {
          setText("");
          socket.emit("chatMessage", obj);
          dispatch(
            updateChatHead({
              id: a?.applications[0]?.applicant?._id,
              data: { type: "increment", jobId: a?._id },
            })
          );
        });
      } else {
        dispatch(getChats(a?.job?._id)).then((res) => {
          setText("");
          socket.emit("chatMessage", obj);
          dispatch(
            updateChatHead({
              id: a?.createdBy?._id,
              data: { type: "increment", jobId: a?.job?._id },
            })
          );
        });
      }
    });
  };

  let allChats = useSelector((state) => state.job.allChats);
  useEffect(() => {
    if (id && user) {
      let chatToOpen = "";
      if (user?.role == "loved-one") {
        chatToOpen = chats?.find((chat) => chat._id === id);
      } else {
        chatToOpen = chats?.find((chat) => chat?.job?._id === id);
      }
      console.log(chats);
      if (chatToOpen) {
        dispatch(getChats(id)).then((res) => {
          setOpenChats([chatToOpen]);
          setLastOpenChat(`chat`);
          navigate(`/chat/${id}`);
          setTimeout(() => {
            var element = document.getElementById(`message-${id}`);
            if (element && element?.scrollHeight) {
              element.scrollTop = element?.scrollHeight;
            }
          }, 500);
        });
      }
    }
  }, [id, chats, navigate, user]);

  useEffect(() => {
    if (user) {
      dispatch(getChatHead()).then((res) => {
        if (res?.payload?.data) {
          let count = res?.payload?.data?.reduce((a, b) => a.count + b.count);
          if (count > 0) {
            document.getElementById("badgeNew").style.display = "flex";
            document.getElementById("badgeNew").innerHTML = count;
          } else {
            document.getElementById("badgeNew").style.display = "none";
          }
        }
      });
    }
  }, [user]);

  useEffect(() => {
    if (chats?.length) {
      if (user?.role === "loved-one") {
        for (let i = 0; i < chats.length; i++) {
          socket.emit("joinRoom", chats[i]?._id);
        }
      } else {
        for (let i = 0; i < chats.length; i++) {
          socket.emit("joinRoom", chats[i]?.job?._id);
        }
      }
    }
  }, [chats]);

  const onInputFocus = (a) => {
    let id = "";
    if (user?.role == "loved-one") {
      id = a?._id;
    } else {
      id = a?.job?._id;
    }
    dispatch(
      updateChatHead({ id: user?._id, data: { type: "", jobId: id } })
    ).then(() => {
      dispatch(getChatHead()).then((res) => {
        if (res?.payload?.data) {
          let count = res?.payload?.data?.reduce((a, b) => a.count + b.count);
          if (count > 0) {
            document.getElementById("badgeNew").style.display = "flex";
            document.getElementById("badgeNew").innerHTML = count;
          } else {
            document.getElementById("badgeNew").style.display = "none";
          }
        }
      });
    });
  };

  return (
    <div className="chatMain">
      <Container>
        <Row>
          <Col md={3}>
            <div className="main-chatList">
              {chats?.map((a, i) =>
                user?.role === "loved-one" ? (
                  <div
                    key={i}
                    className={`list ${id == a._id ? "active" : ""}`}
                    onClick={() => setChat(a)}
                  >
                    <div className="imgDiv">
                      <img
                        src={
                          a?.applications[0]?.applicant?.image
                            ? `${a?.applications[0]?.applicant?.image}`
                            : "https://static.vecteezy.com/system/resources/thumbnails/000/439/863/small/Basic_Ui__28186_29.jpg"
                        }
                      />
                    </div>
                    <div className="nameDiv">
                      <div className="heading">
                        <h6>{a?.applications[0]?.applicant?.name}</h6>
                      </div>
                      <div className="text-div">
                        <p>{a?.title}</p>
                      </div>
                    </div>
                  </div>
                ) : (
                  <div
                    key={i}
                    className={`list ${id == a?.job?._id ? "active" : ""}`}
                    onClick={() => setChat(a)}
                  >
                    <div className="imgDiv">
                      <img
                        src={
                          a?.createdBy?.image
                            ? `${a?.createdBy?.image}`
                            : "https://static.vecteezy.com/system/resources/thumbnails/000/439/863/small/Basic_Ui__28186_29.jpg"
                        }
                      />
                    </div>
                    <div className="nameDiv">
                      <div className="heading">
                        <h6>{a?.createdBy?.name}</h6>
                      </div>
                      <div className="text-div">
                        <p>{a?.job?.title}</p>
                      </div>
                    </div>
                  </div>
                )
              )}
            </div>
          </Col>
          <Col md={9}>
            {openChats.map((a, i) => (
              <div key={i} className="main-allChats" id={`chat`}>
                <div className="chatHeader">
                  <div className="heading text-center d-flex align-items-center justify-content-between w-100">
                    {user?.role === "loved-one" ? (
                      <h6>{a?.applications[0]?.applicant?.name}</h6>
                    ) : (
                      <h6>{a?.createdBy?.name}</h6>
                    )}
                  </div>
                </div>
                <div className="messagesDiv">
                  <div className="messages" id={`message-${a._id}`}>
                    {user?.role === "loved-one"
                      ? allChats
                          .filter((c) => c.project_id === a._id)[0]
                          ?.chats.map((b, i) =>
                            b.sender_id?._id === user._id ? (
                              <div className="message to">{b.message}</div>
                            ) : (
                              <div className="message from">{b.message}</div>
                            )
                          )
                      : allChats
                          .filter((c) => c.project_id === a?.job?._id)[0]
                          ?.chats.map((b, i) =>
                            b.sender_id?._id === user._id ? (
                              <div className="message to">{b.message}</div>
                            ) : (
                              <div className="message from">{b.message}</div>
                            )
                          )}
                  </div>
                  <form onSubmit={(e) => send(a, e)}>
                    <div className="messageBox">
                      <input
                        value={text}
                        type="text"
                        placeholder="Type a message"
                        onChange={(e) => setText(e.target.value)}
                        onFocus={() => onInputFocus(a)}
                      />
                      <button type="submit">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="22"
                          height="22"
                          fill="currentColor"
                          class="bi bi-send-fill"
                          viewBox="0 0 16 16"
                        >
                          <path d="M15.964.686a.5.5 0 0 0-.65-.65L.767 5.855H.766l-.452.18a.5.5 0 0 0-.082.887l.41.26.001.002 4.995 3.178 3.178 4.995.002.002.26.41a.5.5 0 0 0 .886-.083zm-1.833 1.89L6.637 10.07l-.215-.338a.5.5 0 0 0-.154-.154l-.338-.215 7.494-7.494 1.178-.471z" />
                        </svg>
                      </button>
                    </div>
                  </form>
                </div>
              </div>
            ))}
          </Col>
        </Row>
      </Container>
    </div>
  );
}
export default Chat;
