import { createAsyncThunk } from "@reduxjs/toolkit";
import { get } from "../../apiService";

export const getTransaction = createAsyncThunk(
  "getTransaction",
  (payload) =>
    new Promise((resolve, reject) => {
      let url = "payment/get-payment-intent";
      resolve(get(url));
    })
);
