import { createAsyncThunk } from "@reduxjs/toolkit";
import { get, patch, post, postWithoutToken, put } from "../../apiService";

export const getUser = createAsyncThunk(
  "getUser",
  (id) =>
    new Promise((resolve, reject) => {
      let url = `/user/${id}`;
      resolve(get(url));
    })
);

export const reportUser = createAsyncThunk(
  "reportUser",
  (data) =>
    new Promise((resolve, reject) => {
      let url = `/complaint/`;
      resolve(post(url, { data }));
    })
);

export const createPaymentIntent = createAsyncThunk(
  "createPaymentIntent",
  (data) =>
    new Promise((resolve, reject) => {
      let url = `payment/create-payment-intent`;
      resolve(post(url, { data }));
    })
);

export const createWithdrawRequest = createAsyncThunk(
  "createWithdrawRequest",
  (data) =>
    new Promise((resolve, reject) => {
      let url = `withdrawrequests/create-withdraw-request`;
      resolve(post(url, data));
    })
);

export const getWithdrawRequests = createAsyncThunk(
  "getWithdrawRequests",
  () =>
    new Promise((resolve, reject) => {
      let url = `withdrawrequests/get-withdraw-requests`;
      resolve(get(url));
    })
);

export const purchaseSubscription = createAsyncThunk(
  "purchaseSubscription",
  (data) =>
    new Promise((resolve, reject) => {
      let url = `payment/purchase-subscription`;
      resolve(post(url, { data }));
    })
);

export const updateChatHead = createAsyncThunk(
  "updateChatHead",
  ({ id, data }) =>
    new Promise((resolve, reject) => {
      let url = `chatHead/${id}`;
      resolve(put(url, { data }));
    })
);

export const getChatHead = createAsyncThunk(
  "getChatHead",
  () =>
    new Promise((resolve, reject) => {
      let url = `chatHead/`;
      resolve(get(url));
    })
);