import React, { useEffect } from "react";
import { Accordion } from "react-bootstrap";

import GetStarted from "../../Components/GetStarted/GetStarted";

import {
  Cat1,
  Cat2,
  Cat3,
  Cat4,
  Cat5,
  Cat6,
  Cat7,
  Cat8,
  Person1,
  Person2,
  Person3,
  Person4,
  Person5,
  Person6,
  Person7,
  Person8,
} from "../../assets/images";

let persons = [
  {
    img: Person1,
    bg: "#643D46",
  },
  {
    img: Person2,
    bg: "#C56320",
  },
  {
    img: Person3,
    bg: "#603D10",
  },
  {
    img: Person4,
    bg: "#501068",
  },
  {
    img: Person5,
    bg: "#126B8E",
  },
  {
    img: Person6,
    bg: "#1B8463",
  },
  {
    img: Person7,
    bg: "#730000",
  },
  {
    img: Person8,
    bg: "#006D6B",
  },
];

let categories = [
  {
    name: "Household Help",
    img: Cat1,
  },
  {
    name: "Healthcare Appointment",
    img: Cat2,
  },
  {
    name: "Salon/SPA Appointment",
    img: Cat3,
  },
  {
    name: "Grocery Runs",
    img: Cat4,
  },
  {
    name: "Travel Companions",
    img: Cat5,
  },
  {
    name: "Personal Driver",
    img: Cat6,
  },
  {
    name: "Lively-hood & Exercise",
    img: Cat7,
  },
  {
    name: "Shopping",
    img: Cat8,
  },
];

function Faqs() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div className="FaqsMain">
      <div className="banner-sec mb-2">
        <div className="container d-flex align-items-center justify-content-center h-100">
          <div className="heading">
            <h1>Faqs</h1>
          </div>
        </div>
      </div>

      <div className="container pt-5 pb-5">
        <Accordion defaultActiveKey="0">
          <Accordion.Item eventKey="0">
            <Accordion.Header>
              What is Kaytree?
            </Accordion.Header>
            <Accordion.Body>
              Kaytree is a bridge between generations that fosters relationships while filling a need of
              help (loved ones) and companion care.
              <br />
              <strong>How much does this cost?</strong>
              <br />
              Your initial payment of start up fees plus only 5% of all payment and receivables.
              <br />
              <strong>  What is a credit?</strong>
              <br />
              Purchase credits are used to hire and one credit is equivalent to $1. You will see less in
              your balance due to the 5% fee.
            </Accordion.Body>
          </Accordion.Item>
          <Accordion.Item eventKey="1">
            <Accordion.Header>
              How do I open an account?
            </Accordion.Header>
            <Accordion.Body>
              Start by selecting “Register” on the home page.
              <br />
              Select either “I want to work” (Companion) or “I want to Hire” (Loved One)
              and create an account.
              <br />
              On the dashboard screen you will be prompted to pay for a One Time
              Startup fee. This fee includes a non-refundable back ground check to
              ensure both Companions over the age of 18 and Loved Ones are
              screened.
              <br />
              Complete the Profile page with name, full address, state, zip code, and
              date of birth.
              <br />
              Upload a profile picture of yourself to personalize your profile Proceed through the background check process. Once completed y
            </Accordion.Body>
          </Accordion.Item>
          <Accordion.Item eventKey="2">
            <Accordion.Header>
              Why do I need a background check?
            </Accordion.Header>
            <Accordion.Body>
              To ensure a healthy, safe environment for Companions and Loved Ones
              Kaytree requires all adult users complete a back ground check.
            </Accordion.Body>
          </Accordion.Item>
          <Accordion.Item eventKey="3">
            <Accordion.Header>
              How do I hire someone?
            </Accordion.Header>
            <Accordion.Body>
              Select “Dashboard” from the top menu.
              <br />
              Select “Create New Job”
              <br />
              Select the type and set a date(s) for job needed.
              <br />
              You may verify the job was properly created by selecting “Jobs Request” to
              see all your open Jobs. Enter job details such as destinations and expected
              time required.
              <br />
              In “Dashboard” select “Pending Jobs” to Hire a job applicant.
              <br />
              You may directly message your hired Companion for job details.
            </Accordion.Body>
          </Accordion.Item>
          <Accordion.Item eventKey="4">
            <Accordion.Header>
              How do I get hired for Jobs?
            </Accordion.Header>
            <Accordion.Body>
              Select “Dashboard” from the top menu.
              <br />
              Select “Jobs” from the left hand menu and click “xxx”
              <br />
              Select Jobs near you
              <br />
              You may directly message your hired Loved One for job details.
            </Accordion.Body>
          </Accordion.Item>
          <Accordion.Item eventKey="4">
            <Accordion.Header>
              What services are allowed?
            </Accordion.Header>
            <Accordion.Body>
              Please see job pull down for ideas or create your own such as travel
              companion, shopping, household chores, drivers, etc…
            </Accordion.Body>
          </Accordion.Item>
        </Accordion>
      </div>
      <GetStarted />
    </div>
  );
}

export default Faqs;
