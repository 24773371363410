import React from "react";

import "./GetStarted.scss";
import { Container } from "react-bootstrap";
import { NavLink } from "react-router-dom";

function GetStarted() {
  return (
    <div className="getStartedMain">
      <Container>
        <div className="getStartedDiv">
          <div>
            <div className="heading">
              <h3>Let’s Get Started</h3>
            </div>
            <div className="text-div">
              <p>
                Ready to be a part of change? Bring forth your best abilities to
                connect with people and their stories. Start your job search
                now.
              </p>
            </div>
          </div>
          <div className="btn-div">
            <NavLink to="/contact-us">
              <button>Get Started Now</button>
            </NavLink>
          </div>
        </div>
      </Container>
    </div>
  );
}

export default GetStarted;
