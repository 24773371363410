import React from "react";
import { Col, Row } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { getFormValues } from "../../../helper";
import { resetPassword } from "../../../redux/authSlice/actions";

function ResetPass() {
  let dispatch = useDispatch();
  let navigate = useNavigate();

  let error = useSelector((state) => state.auth.error);

  const resetPass = (e) => {
    e.preventDefault();
    let obj = getFormValues(e);
    let resetToken = localStorage.getItem("resetToken");
    dispatch(resetPassword({ payload: obj, resetToken })).then((res) => {
      if (res.payload?.status === "success") {
        navigate("/login");
      }
    });
  };

  return (
    <div className=" authMain loginMain container-fluid">
      <Row className="h-100">
        <Col md={6} className="h-100 p-0 d-none d-md-block">
          <div className="imageDiv d-flex flex-column align-items-center justify-content-center row-gap-5">
            <div className="imgDiv text-center">
              <img
                width={"80%"}
                src={require("../../../assets/images/person.png")}
              />
            </div>
          </div>
        </Col>
        <Col md={6} className="h-100 p-0">
          <div className="formDiv d-flex flex-column align-items-center justify-content-center login h-100">
            <div className="logoDiv d-block d-md-none mb-5">
              <img
                width={120}
                src={require("../../../assets/images/logo.png")}
              />
            </div>
            <div className="heading w-100">
              <div className="w-100 d-flex align-items-center justify-content-between">
                <h6>
                  Welcome to <span>Kaytree</span>
                </h6>
              </div>
              <h2>Reset Your Password</h2>
            </div>

            <form onSubmit={(e) => resetPass(e)}>
              <div className="inputDiv">
                <label>Password</label>
                <input
                  name="password"
                  type="password"
                  placeholder="Type your Password"
                />
              </div>
              <div className="inputDiv">
                <label>Confirm Password</label>
                <input
                  name="confirmPassword"
                  type="password"
                  placeholder="Confirm Password"
                />
              </div>

              {/* {error?.message &&
                                <div className='error mt-2'>
                                    <Alert variant="danger">
                                        {error?.message}
                                    </Alert>
                                </div>
                            } */}

              <div className="btn-div">
                <button type="submit">Reset</button>
              </div>
            </form>
          </div>
        </Col>
      </Row>
    </div>
  );
}

export default ResetPass;
