import React, { useEffect, useState } from "react";
import { Col, Row } from "react-bootstrap";
import { useSelector } from "react-redux";
import { NavLink, useNavigate } from "react-router-dom";

function Dashboard() {
  let { user, wallet, completedJobs, createdJobs, transactions, totalDeposit, appliedJobs, ongoingJobs, totalWithdraw } =
    useSelector((state) => state.auth);

  let [d, setD] = useState(0);

  useEffect(() => {
    if (user?.role === "loved-one") {
      if (totalDeposit) {
        let t = 0;
        for (let i = 0; i < totalDeposit.length; i++) {
          t += Number(totalDeposit[i].amount);
        }
        setD(t);
      }
    } else if (user?.role === "companion") {
      if (totalWithdraw) {
        let t = 0;
        for (let i = 0; i < totalWithdraw.length; i++) {
          t += Number(totalWithdraw[i].amount);
        }
        setD(t);
      }
    }
  }, [totalDeposit, totalWithdraw]);
  const navigate = useNavigate()
  return (
    <div className="dashboardMain">
      <Row className="row-gap-4">
        {user?.role === "companion" ? <Col md={4} onClick={() => navigate("/jobs")}>
          <div className="overViewDiv balance">
            <div className="heading">
              <h6>Finds Jobs</h6>
              {/* <h1>{wallet?.credits ? Number(wallet?.credits).toFixed(2) : "-"}</h1> */}
            </div>
            {/* <div className='detailDiv'>
                            <NavLink to="/user/transactions">View Details</NavLink>
                        </div> */}
          </div>
        </Col> : <Col md={4} onClick={() => navigate("/user/job/create")}>
          <div className="overViewDiv balance">
            <div className="heading">
              <h6>Create New Job</h6>
              {/* <h1>{wallet?.credits ? Number(wallet?.credits).toFixed(2) : "-"}</h1> */}
            </div>
            {/* <div className='detailDiv'>
                            <NavLink to="/user/transactions">View Details</NavLink>
                        </div> */}
          </div>
        </Col>}
        {user?.role !== "companion" ? <Col md={4}>
          <div className="overViewDiv balance">
            <div className="heading">
              <h6>Account Credit</h6>
              <h1>{wallet?.credits ? Number(wallet?.credits).toFixed(2) : "-"}</h1>
            </div>
            {/* <div className='detailDiv'>
                            <NavLink to="/user/transactions">View Details</NavLink>
                        </div> */}
          </div>
        </Col>
          : <Col md={4}>
            <div className="overViewDiv balance">
              <div className="heading">
                <h6>Account Balance</h6>
                <h1>{wallet?.credits ? Number(wallet?.credits).toFixed(2) : "-"}</h1>
              </div>
              {/* <div className='detailDiv'>
                  <NavLink to="/user/transactions">View Details</NavLink>
              </div> */}
            </div>
          </Col>}
        {/* {user?.role !== "companion" ? (
          <Col md={4}>
            <div className="overViewDiv deposit">
              <div className="heading">
                <h6>TOTAL DEPOSIT</h6>
                <h1>{d || "-"}</h1>
              </div>
            </div>
          </Col>
        )
          :
          <Col md={4}>
            <div className="overViewDiv deposit">
              <div className="heading">
                <h6>TOTAL WITHDRAW</h6>
                <h1>{d || "-"}</h1>
              </div>
            </div>
          </Col>
        } */}

        {user?.role === "companion" && (
          <Col md={4}>
            <div className="overViewDiv withdraw">
              <div className="heading">
                <h6>APPLIED JOBS</h6>
                <h1>{appliedJobs || "-"}</h1>
              </div>
              <div className="detailDiv">
                <NavLink to="/user/job/applied">View Details</NavLink>
              </div>
            </div>
          </Col>
        )}

        {user?.role !== "companion" && (
          <Col md={4}>
            <div className="overViewDiv jobsCreated">
              <div className="heading">
                <h6>JOBS CREATED</h6>
                <h1>{createdJobs}</h1>
              </div>
              <div className="detailDiv">
                <NavLink to="/user/job/history">View Details</NavLink>
              </div>
            </div>
          </Col>
        )}
        {user?.role === "companion" && (
          <Col md={4}>
            <div className="overViewDiv completedJobs">
              <div className="heading">
                <h6>ONGOING JOBS</h6>
                <h1>{ongoingJobs || "-"}</h1>
              </div>
              <div className="detailDiv">
                <NavLink to="/user/job/in-progress">View Details</NavLink>
              </div>
            </div>
          </Col>
        )}
        <Col md={4}>
          <div className="overViewDiv completedJobs">
            <div className="heading">
              <h6>COMPLETED JOBS</h6>
              <h1>{completedJobs || "-"}</h1>
            </div>
            <div className="detailDiv">
              <NavLink to="/user/job/finished">View Details</NavLink>
            </div>
          </div>
        </Col>

        {user?.role !== "companion" && (
          <Col md={4}>
            <div className="overViewDiv completedJobs">
              <div className="heading">
                <h6>PENDING JOBS</h6>
                <h1>{Number(createdJobs) - Number(completedJobs) || "-"}</h1>
              </div>
              <div className="detailDiv">
                <NavLink to="/user/job/ongoing-jobs">View Details</NavLink>
              </div>
            </div>
          </Col>
        )}



        <Col md={4}>
          <div className="overViewDiv transactions">
            <div className="heading">
              <h6>Reviews</h6>
              {/* <h1>{transactions || "-"}</h1> */}
            </div>
            <div className='detailDiv'>
              <NavLink to={`/profile/${user._id}`}>View Details</NavLink>
            </div>
          </div>
        </Col>
      </Row>

      {/* {user?.role === "loved-one" ?
        <div className="btn-div mt-5 text-center">
          <NavLink to='/user/job/create' style={{ margin: "auto" }}>
            <button style={{ width: 200, height: 40, fontSize: 16 }}>Create Job</button>
          </NavLink>
        </div>
        :
        user?.role === "companion" ?
          <div className="btn-div mt-5 text-center">
            <NavLink to='/jobs' style={{ margin: "auto" }}>
              <button style={{ width: 200, height: 40, fontSize: 16 }}>Apply for a job</button>
            </NavLink>
          </div>
          :
          null
      } */}
    </div >
  );
}

export default Dashboard;
