import { createSlice } from '@reduxjs/toolkit';
import { addCaseWithLoading } from '../../helper';
import * as Actions from './actions';

const initialState = {
    isLoading: false,
    error: null,
    user: {},
    withdrawRequests:[],
    chatHeads: []
};

const userSlice = createSlice({
    name: 'user',
    initialState,
    reducers: {},

    extraReducers: (builder) => {
        addCaseWithLoading(builder, Actions.getUser, {
            onCompleted: (state, action) => {
                state.isLoading = false;
                state.user = action.payload.data
            },
            onPending: (state) => {
                state.isLoading = true;
                state.error = null;
            },
            onReject: (state, error) => {
                state.isLoading = false;
                state.error = error?.error
            },
        });
        addCaseWithLoading(builder, Actions.getWithdrawRequests, {
            onCompleted: (state, action) => {
                state.isLoading = false;
                state.withdrawRequests = action.payload.data
            },
            onPending: (state) => {
                state.isLoading = true;
                state.error = null;
            },
            onReject: (state, error) => {
                state.isLoading = false;
                state.error = error?.error
            },
        });

        addCaseWithLoading(builder, Actions.getChatHead, {
            onCompleted: (state, action) => {
                state.isLoading = false;
                state.chatHeads = action.payload.data
            },
            onPending: (state) => {
                state.isLoading = true;
                state.error = null;
            },
            onReject: (state, error) => {
                state.isLoading = false;
                state.error = error?.error
            },
        });
    },
});

export default userSlice.reducer;