import React, { useEffect, useState } from "react";
import { Badge, Table } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { NavLink } from "react-router-dom";
import ReviewModal from "../../../Components/ReviewModal/ReviewModal";
import { getAllApplications } from "../../../redux/applicationSlice/actions";
import {
  completeUserJob,
  getPostedJobs
} from "../../../redux/jobSlice/actions";
import { getWithdrawRequests } from "../../../redux/userSlice/actions";
// import ReviewModal from "../../../Components/ReviewModal/ReviewModal";

function WithdrawHistory() {
  // const [showModal, setShowModal] = useState(false);
  // const [selectedRequest, setSelectedRequest] = useState(null);

  const dispatch = useDispatch();
  let withdrawRequests = useSelector((state) => state.user.withdrawRequests);

  useEffect(() => {
    dispatch(getWithdrawRequests()).then((res) => {
      console.log(res);
    });
  }, []);

  // const handleCloseModal = () => setShowModal(false);
  // const openReviewModal = (request) => {
  //   setSelectedRequest(request);
  //   setShowModal(true);
  // };

  return (
    <div className="withdrawHistoryMain p-3">
      {/* <ReviewModal
        show={showModal}
        handleClose={handleCloseModal}
        selectedRequest={selectedRequest}
        getData={() => {}}
      /> */}

      <div className="tableDiv">
        <Table bordered hover>
          <thead className="text-center">
            <tr>
              <th>S. no</th>
              <th>Amount</th>
              <th>Bank Name</th>
              <th>Status</th>
              <th>Date</th>
              {/* <th>Action</th> */}
            </tr>
          </thead>
          <tbody>
            {withdrawRequests.length === 0 ? (
              <tr>
                <td colSpan="6" className="text-center">
                  No withdrawal requests found.
                </td>
              </tr>
            ) : (
              withdrawRequests.map((request, i) => (
                <tr key={i}>
                  <td className="text-center">{i + 1}</td>
                  <td>${request.amount}</td>
                  <td>{request.bank_name}</td>
                  <td className="text-center">
                    {request.status === "pending" ? (
                      <Badge pill bg="secondary">
                        Pending
                      </Badge>
                    ) : request.status === "approved" ? (
                      <Badge pill bg="success">
                        Approved
                      </Badge>
                    ) : (
                      <Badge pill bg="danger">
                        Declined
                      </Badge>
                    )}
                  </td>
                  <td>{request.createdAt.split("T")[0]}</td>
                  {/* <td className="text-center">
                    <button
                      onClick={() => openReviewModal(request)}
                      className="btn btn-primary"
                    >
                      View Details
                    </button>
                  </td> */}
                </tr>
              ))
            )}
          </tbody>
        </Table>
      </div>
    </div>
  );
}

export default WithdrawHistory;
