import { createAsyncThunk } from "@reduxjs/toolkit";
import {
  get,
  patch,
  postWithFormData,
  postWithoutToken,
} from "../../apiService";

export const registerUser = createAsyncThunk(
  "auth/register",
  ({ payload }) =>
    new Promise((resolve, reject) => {
      resolve(postWithoutToken(`auth/signup`, payload));
    })
);

export const verifyEmail = createAsyncThunk(
  "auth/verify-otp",
  ({ payload }) =>
    new Promise((resolve, reject) => {
      resolve(patch(`auth/verify-otp/${payload.otp}`));
    })
);

export const resendOtpCode = createAsyncThunk(
  "auth/resend-verification-otp",
  ({ payload }) =>
    new Promise((resolve, reject) => {
      resolve(patch(`auth/resend-verification-otp`, payload));
    })
);

export const loginUser = createAsyncThunk(
  "auth/login",
  ({ payload }) =>
    new Promise((resolve, reject) => {
      resolve(postWithoutToken(`auth/login`, payload));
    })
);

export const forgotPassword = createAsyncThunk(
  "auth/forget-password",
  ({ payload }) =>
    new Promise((resolve, reject) => {
      resolve(postWithoutToken(`auth/forget-password`, payload));
    })
);

export const verifyOtpReset = createAsyncThunk(
  "auth/verify-fp-otp",
  ({ payload }) =>
    new Promise((resolve, reject) => {
      resolve(postWithoutToken(`auth/verify-fp-otp/${payload.otp}`));
    })
);

export const resetPassword = createAsyncThunk(
  "auth/reset-password",
  ({ payload, resetToken }) =>
    new Promise((resolve, reject) => {
      resolve(postWithoutToken(`auth/reset-password/${resetToken}`, payload));
    })
);

export const getUserDetail = createAsyncThunk(
  "user/get/me",
  () =>
    new Promise((resolve, reject) => {
      resolve(get(`user/get/me`));
    })
);

export const updateUserData = createAsyncThunk(
  "update-user",
  ({ payload }) =>
    new Promise((resolve, reject) => {
      resolve(patch(`user/get/me`, payload));
    })
);

export const upload = createAsyncThunk(
  "upload",
  ({ payload, upload }) =>
    new Promise((resolve, reject) => {
      resolve(postWithFormData(`user/upload-image`, payload, upload));
    })
);
