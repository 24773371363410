import React, { useEffect, useState } from "react";
import { Col, Row } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { createWithdrawRequest } from "../../../redux/userSlice/actions";
import { showErrorToast } from "../../../apiService";

function WithdrawNow() {
  const dispatch = useDispatch();
  const user = useSelector((state) => state.auth.user);
  const [formData, setFormData] = useState({
    amount: "",
    account_number: "",
    bank_name: "",
  });

  useEffect(() => {
    setFormData((prevData) => ({
      ...prevData,
    }));
  }, []);

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setFormData({ ...formData, [name]: value });
  };
  const clearForm = () => {
    setFormData({
      amount: "",
      account_number: "",
      bank_name: "",
    });
  };
  const submitWithdrawal = async (e) => {
    e.preventDefault();
    if (!user?.identityVerified) {
      showErrorToast("To withdraw, please confirm your identity.");
      return;
    }
    dispatch(createWithdrawRequest(formData)).then((res) => {
      clearForm();
      console.log(res);
    });
  };

  return (
    <div className="createJobMain shadow p-3">
      <div className="formDiv">
        <form onSubmit={submitWithdrawal}>
          <Row>
            <Col md={6}>
              <div className="inputDiv">
                <label>
                  Account Number<sup className="text-red">*</sup>
                </label>
                <input
                  value={formData.account_number}
                  onChange={handleInputChange}
                  name="account_number"
                  type="number"
                  required
                />
              </div>
            </Col>
            <Col md={6}>
              <div className="inputDiv">
                <label>
                  Bank Name<sup className="text-red">*</sup>
                </label>
                <input
                  value={formData.bank_name}
                  onChange={handleInputChange}
                  name="bank_name"
                  type="text"
                  required
                />
              </div>
            </Col>
          </Row>
          <Row>
            <Col md={6}>
              <div className="inputDiv">
                <label>
                  Amount<sup className="text-red">*</sup>
                </label>
                <input
                  value={formData.amount}
                  onChange={handleInputChange}
                  name="amount"
                  type="number"
                  required
                />
              </div>
            </Col>
          </Row>
          <div className="btn-div">
            <button type="submit">Submit</button>
          </div>
        </form>
      </div>
    </div>
  );
}

export default WithdrawNow;
