import { TimePicker } from "antd";
import dayjs from "dayjs";
import React, { useEffect, useState } from "react";
import { Col, Row } from "react-bootstrap";
import Modal from "react-bootstrap/Modal";
import "react-datepicker/dist/react-datepicker.css";
import { useDispatch, useSelector } from "react-redux";
import { NavLink } from "react-router-dom";
import { Calendar } from "rsuite";
import "rsuite/Calendar/styles/index.css";
import { showErrorToast } from "../../../apiService";
import { editJob, getOneJob } from "../../../redux/jobSlice/actions";
import "./CreateJob.css";
import AutocompleteInput from "./Autocomplete";

// Date format
const format = "MM/DD/YYYY";

function EditJob() {
  const dispatch = useDispatch();
  const user = useSelector((state) => state.auth.user);
  let { wallet } = useSelector((state) => state.auth);
  let getOneJobs = useSelector((state) => state.job?.getOneJob);
  useEffect(() => {
    if (user) {
      let jobId =
        window.location.href.split("/")[
          window.location.href.split("/").length - 1
        ];
      dispatch(getOneJob(jobId));
    }
  }, [user]);

  const [date, setDate] = useState("");
  const [formData, setFormData] = useState({
    category: "Household Help",
    title: "",
    budget: "",
    totalBudget: "",
    totalBudget: "",
    type: "Fixed",
    jobtype: "Single",
    city: "",
    state: "",
    zipcode: "",
    address: "",
    description: "",
    date: date,
    time: "",
    latitude: "",
    longitude: "",
  });

  const [dates, setDates] = useState([]);

  useEffect(() => {
    setFormData({
      category: getOneJobs?.category,
      title: getOneJobs?.title,
      budget: getOneJobs?.budget,
      totalBudget: getOneJobs?.totalBudget,
      type: "Fixed",
      jobtype: "Single",
      city: getOneJobs?.city,
      state: getOneJobs?.state,
      zipcode: getOneJobs?.zipcode,
      address: getOneJobs?.address,
      description: getOneJobs?.description,
      date: getOneJobs?.date,
      time: getOneJobs?.time,
      latitude: getOneJobs?.latitude,
      longitude: getOneJobs?.longitude,
    });
  }, [getOneJobs]);

  useEffect(() => {
    setFormData((prevData) => ({
      ...prevData,
      dates: dates.map((date) => date.format(format)),
    }));
  }, [dates]);

  const handleTimeChange = (times) => {
    if (times.isValid()) {
      const formattedTime = dayjs(times).format("HH:mm:ss");
      setFormData((prev) => ({ ...prev, time: formattedTime }));
    }
  };

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setFormData({ ...formData, [name]: value });
  };
  const postJob = (e) => {
    e.preventDefault();
    if (!user?.identityVerified) {
      showErrorToast("To apply, please confirm your identity.");
      return; // Make sure to exit if identity is not verified
    }
    let jobId =
      window.location.href.split("/")[
        window.location.href.split("/").length - 1
      ];
    dispatch(editJob({ id: jobId, formData })).then((res) => {
      if (res.payload?.status === "success") {
        clearForm();
      }
    });
  };

  const clearForm = () => {
    setFormData({
      category: "",
      title: "",
      budget: "",
      totalBudget: "",
      type: "",
      jobtype: "",
      city: "",
      state: "",
      zipcode: "",
      address: "",
      description: "",
      scheduling: [],
      date: "",
      time: "",
    });
    setDates([]);
  };

  const handleSingleDateClick = (dates) => {
    setFormData((prev) => ({
      ...prev,
      date: dates,
    }));
    setDate(dates);
  };

  const calculatePercentage = (budget) => {
    if (budget) {
      return budget * 0.05;
    }
    return 0;
  };

  // Update the formData state
  useEffect(() => {
    const percentage = calculatePercentage(formData.budget);
    setFormData((prev) => ({
      ...prev,
      totalBudget: Number(formData.budget) + percentage, // Keeping two decimal places
    }));
  }, [formData.budget]); // Runs whenever the budget changes

  const canSubmitJob = () => {
    return formData.budget < wallet?.credits;
  };

  const creditsModalVisible = !canSubmitJob();
  console.log(formData);

  return (
    <div className="createJobMain shadow p-3">
      <div className="formDiv">
        <form onSubmit={(e) => (user?.subscribed ? postJob(e) : null)}>
          <div className="inputDiv">
            <label>
              Category<sup className="text-red">*</sup>
            </label>
            <select
              value={formData.category}
              onChange={handleInputChange}
              name="category"
              required
            >
              <option disabled>Select Category</option>
              <option>Household Help</option>
              <option>Healthcare Appointment</option>
              <option>Salon/SPA Appointment</option>
              <option>Grocery Runs</option>
              <option>Travel Companions</option>
              <option>Personal Driver</option>
              <option>Lively-hood & Exercise</option>
              <option>Shopping</option>
              <option>Other</option>
            </select>
          </div>
          <div className="inputDiv">
            <label>
              Job Title<sup className="text-red">*</sup>
            </label>
            <input
              value={formData.title}
              onChange={handleInputChange}
              name="title"
              type="text"
              required
            />
          </div>

          <Row>
            <Col md={4}>
              <div className="inputDiv">
                <label>
                  Amount<sup className="text-red">*</sup>
                </label>
                <input
                  value={formData.budget}
                  onChange={handleInputChange}
                  name="budget"
                  type="number"
                  required
                />
              </div>
            </Col>
            <Col md={4}>
              <div className="inputDiv">
                <label>
                  You Will Be Charged Plus (5%)
                  <sup className="text-red">*</sup>
                </label>
                <input
                  value={formData.totalBudget}
                  // onChange={handleInputChange}
                  name="totalBudget"
                  type="number"
                  disabled
                />
              </div>
            </Col>
            <Col md={4}>
              <div className="inputDiv">
                <label>
                  Job Type<sup className="text-red">*</sup>
                </label>
                <select
                  value={formData.type}
                  onChange={handleInputChange}
                  name="type"
                  required
                >
                  <option>Fixed</option>
                  {/* <option>Hourly</option> */}
                </select>
              </div>
            </Col>
          </Row>
          <Row>
            <Col md={12}>
              <AutocompleteInput
                title="Job Location"
                setFormData={setFormData}
                formData={formData}
              />
            </Col>
            <Col md={4}>
              <div className="inputDiv">
                <label>
                  City<sup className="text-red">*</sup>
                </label>
                <input
                  value={formData.city}
                  onChange={handleInputChange}
                  name="city"
                  type="text"
                  required
                />
              </div>
            </Col>
            <Col md={4}>
              <div className="inputDiv">
                <label>
                  State<sup className="text-red">*</sup>
                </label>

                <input
                  name="state"
                  type="text"
                  value={formData.state}
                  onChange={handleInputChange}
                  required
                />
              </div>
            </Col>
            <Col md={4}>
              <div className="inputDiv">
                <label>
                  Zip Code<sup className="text-red">*</sup>
                </label>
                <input
                  name="zipcode"
                  type="number"
                  value={formData.zipcode}
                  onChange={handleInputChange}
                  required
                />
              </div>
            </Col>
          </Row>
          {/* <div className="inputDiv">
            <label>
              Job Location<sup className="text-red">*</sup>
            </label>
            <textarea
              value={formData.address}
              onChange={handleInputChange}
              name="address"
              rows={5}
              required
            />
          </div> */}
          <div className="inputDiv">
            <label>
              Job Description<sup className="text-red">*</sup>
            </label>
            <textarea
              value={formData.description}
              onChange={handleInputChange}
              name="description"
              rows={6}
              required
            />
          </div>
          <div className="inputDiv">
            <label>
              Time<sup className="text-red">*</sup>
            </label>
            <TimePicker
              // value={formData.time}
              onChange={handleTimeChange}
              size="large"
            />
          </div>
          <div className="inputDiv">
            <label>
              Scheduling<sup className="text-red">*</sup>
            </label>

            <Calendar
              bordered
              cellClassName={(date) =>
                date.getDay() % 2 ? "bg-gray" : undefined
              }
              onSelect={handleSingleDateClick}
            />
          </div>
          <>
            {creditsModalVisible ? (
              <Modal show={creditsModalVisible} centered>
                <Modal.Header className="d-flex items-center justify-content-center">
                  <Modal.Title className="text-center">
                    Insufficient Credits
                  </Modal.Title>
                </Modal.Header>
                <Modal.Body className="d-flex items-center flex-column justify-center">
                  <p className="text-center">Purchase Credits to Continue</p>
                  <div className="modal-div">
                    <NavLink to="/user/purchase-credit">
                      <button>Purchase</button>
                    </NavLink>
                  </div>
                </Modal.Body>
              </Modal>
            ) : (
              <div className="btn-div">
                <button type="submit">Submit</button>
              </div>
            )}
          </>
        </form>
      </div>
    </div>
  );
}

export default EditJob;
