import React, { useState, useEffect } from "react";
import {
  CardElement,
  Elements,
  useElements,
  useStripe,
  PaymentRequestButtonElement,
} from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import { useDispatch, useSelector } from "react-redux";
import { createPaymentIntent } from "../../../redux/userSlice/actions";
import { getUserDetail } from "../../../redux/authSlice/actions";

const stripePromise = loadStripe("pk_test_51Q7gszFM2Z7VvSwOVWs1S09IlCM56SMvlqHn7ENKJ1jCexAZSJaZmZd2zWuGVtlJiZa3EG6nluQaoK1LfCvsCAxZ00ejePKLak");


const dollarsToCents = (dollars) => {
  if (typeof dollars !== "number" || isNaN(dollars)) {
    throw new Error("Input must be a valid number");
  }
  return Math.round(dollars * 100);
};

const CheckoutForm = () => {
  const stripe = useStripe();
  const elements = useElements();
  const [isProcessing, setIsProcessing] = useState(false);
  const [paymentMessage, setPaymentMessage] = useState("");
  const [credits, setCredits] = useState(100);
  const dollar = dollarsToCents(credits);

  const user = useSelector((state) => state.auth.user);
  const dispatch = useDispatch();

  const [paymentRequest, setPaymentRequest] = useState(null);
  const [canMakePayment, setCanMakePayment] = useState(false);

  // Create the payment request for Google Pay and Apple Pay
  useEffect(() => {
    if (stripe) {
      const pr = stripe.paymentRequest({
        country: "US",
        currency: "usd",
        total: {
          label: "Total Amount",
          amount: dollar,
        },
        requestPayerName: true,
        requestPayerEmail: true,
      });

      setPaymentRequest(pr);

      pr.canMakePayment().then((result) => {
        setCanMakePayment(!!result);
      });
    }
  }, [stripe, dollar]);

  const handleSubmit = async (event) => {
    event.preventDefault();
    if (!stripe || !elements) return;

    setIsProcessing(true);
    const cardElement = elements.getElement(CardElement);
    const { error, paymentMethod } = await stripe.createPaymentMethod({
      type: "card",
      card: cardElement,
    });

    if (error) {
      setPaymentMessage(error.message);
      setIsProcessing(false);
    } else {
      await processPayment(paymentMethod.id);
    }
  };

  const processPayment = async (paymentMethodId) => {
    const res = await dispatch(createPaymentIntent({ paymentData: { amount: dollar, currency: "usd" }, user }));
    if (res.payload?.clientSecret) {
      const { clientSecret } = res.payload;
      const confirmPayment = await stripe.confirmCardPayment(clientSecret, { payment_method: paymentMethodId });
      handlePaymentResult(confirmPayment);
    }
  };

  const handlePaymentResult = (confirmPayment) => {
    if (confirmPayment.error) {
      setPaymentMessage("Payment failed. Please try again.");
    } else {
      setPaymentMessage("Payment successful!");
      setCredits(100); // Reset credits
      dispatch(getUserDetail());
    }
    setIsProcessing(false);
  };

  const cardElementOptions = {
    style: {
      base: {
        iconColor: "#c4f0ff",
        color: "#333",
        fontWeight: "500",
        fontFamily: "Roboto, Open Sans, Segoe UI, sans-serif",
        fontSize: "16px",
        fontSmoothing: "antialiased",
        "::placeholder": { color: "#888" },
      },
      invalid: {
        iconColor: "#ff3860",
        color: "#ff3860",
      },
    },
    hidePostalCode: true,
  };
  const setUsd = (val) => {
    const numericValue = Number(val);
    if (!isNaN(numericValue) && numericValue >= 100) {
      setCredits(numericValue);
    } else {
      setCredits(100);
    }
  };
  // const setUsd = (val) => {
  //   setCredits(val < 100 ? 100 : val);
  // };

  return (
    <>
      <div className="selectAmount">
        <div className="text-center">
          <label htmlFor="customRange3" className="form-label">Purchase Credit</label>
          <input
            onChange={(e) => setUsd(e.target.value)}
            value={credits}
            type="range"
            className="form-range"
            min="100"
            max="5000"
            step="10"
            id="customRange3"
          />
        </div>
        <div>
          for <b>{credits} credit</b> you will be charged <b>${credits}</b>
        </div>
        <div className="mt-5">
          <div className="inputDiv">
            <label>Add custom amount</label>
            <input
              type="number"
              value={credits}
              onChange={(e) => setUsd(e.target.value)}
              onBlur={() => { if (credits < 100) setUsd(100); }}
            />
          </div>
        </div>
      </div>

      <form className="mt-5" onSubmit={handleSubmit} style={{ maxWidth: "100%", margin: "auto" }}>
        <h2>Enter Payment Details</h2>
        <div style={{ padding: "10px", border: "1px solid #ccc", borderRadius: "6px", marginBottom: "10px" }}>
          {canMakePayment && paymentRequest ? (
            <PaymentRequestButtonElement options={{ paymentRequest }} />
          ) : (
            <CardElement options={cardElementOptions} />
          )}
        </div>

        <div className="btn-div d-flex justify-content-center mt-4">
          <button className="m-auto" type="submit" disabled={isProcessing}>
            {isProcessing ? "Processing..." : `Pay $${credits}`}
          </button>
        </div>
        {paymentMessage && <p>{paymentMessage}</p>}
      </form>
    </>
  );
};

function PurchaseCredits() {
  return (
    <div className="shadow p-3">
      <Elements stripe={stripePromise}>
        <CheckoutForm />
      </Elements>
    </div>
  );
}

export default PurchaseCredits;
