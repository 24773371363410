import React, { useEffect, useState } from "react";
import { Badge, Table, Pagination } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import ReviewModal from "../../../Components/ReviewModal/ReviewModal";
import { getAllApplications } from "../../../redux/applicationSlice/actions";
import { getAllJobs } from "../../../redux/jobSlice/actions";
import { NavLink } from "react-router-dom";

function FinishedJobs() {
  const dispatch = useDispatch();
  const allJobs = useSelector((state) => state.job.allJobs);
  const allApplications = useSelector((state) => state.applications.allApplications);
  const user = useSelector((state) => state.auth.user);

  const [showModal, setShowModal] = useState(false);
  const [selectedCompanion, setSelectedCompanion] = useState("");
  const [selectedLovedOne, setSelectedLovedOne] = useState("");
  const [selectedJob, setSelectedJob] = useState("");
  const [searchQuery, setSearchQuery] = useState("");

  // Pagination state
  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 10;

  const handleShowModal = () => setShowModal(true);
  const handleCloseModal = () => setShowModal(false);

  const getData = () => {
    if (user) {
      if (user?.role === "loved-one") {
        dispatch(getAllJobs({ status: "completed" }));
      } else {
        dispatch(getAllApplications({ status: "finished" }));
      }
    }
  };

  useEffect(() => {
    getData();
  }, [user]);

  const openReviewModal = (a) => {
    let obj = a?.applications?.find((a) => a.status === "finished");
    setSelectedCompanion(obj.companion);
    setSelectedJob(obj);
    setShowModal(true);
  };

  const openReviewModalForCompanion = (a) => {
    let obj = a;
    setSelectedJob(obj);
    setSelectedLovedOne(obj.createdBy);
    setShowModal(true);
  };

  // Filter jobs based on search query
  const filteredJobs =
    user?.role === "loved-one"
      ? allJobs?.filter((job) => job?.title.toLowerCase().includes(searchQuery.toLowerCase()))
      : allApplications?.filter((app) => app?.job?.title.toLowerCase().includes(searchQuery.toLowerCase()));

  // Calculate current jobs based on the current page
  const indexOfLastJob = currentPage * itemsPerPage;
  const indexOfFirstJob = indexOfLastJob - itemsPerPage;
  const currentJobs = filteredJobs?.slice(indexOfFirstJob, indexOfLastJob);

  // Total pages
  const totalPages = Math.ceil((filteredJobs?.length || 0) / itemsPerPage);

  return (
    <div className="jobHistoryMain p-3">
      <ReviewModal
        show={showModal}
        handleClose={handleCloseModal}
        selectedCompanion={selectedCompanion}
        selectedLovedOne={selectedLovedOne}
        selectedJob={selectedJob}
        getData={getData}
      />
      <form onSubmit={(e) => e.preventDefault()}>
        <div className="searchInput">
          <input
            type="text"
            placeholder="Search here ..."
            value={searchQuery}
            onChange={(e) => setSearchQuery(e.target.value)}
          />
          <div className="searchIconBtn">
            <button type="submit">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="12"
                height="12"
                fill="#fff"
                className="bi bi-search"
                viewBox="0 0 16 16"
              >
                <path d="M11.742 10.344a6.5 6.5 0 1 0-1.397 1.398h-.001q.044.06.098.115l3.85 3.85a1 1 0 0 0 1.415-1.414l-3.85-3.85a1 1 0 0 0-.115-.1zM12 6.5a5.5 5.5 0 1 1-11 0 5.5 5.5 0 0 1 11 0" />
              </svg>
            </button>
          </div>
        </div>
      </form>
      <div className="tableDiv" style={{ overflowX: "auto" }}>
        <Table bordered hover>
          <thead>
            {user?.role === "loved-one" ? (
              <tr>
                <th>S. no</th>
                <th>Job Title</th>
                <th>Rate</th>
                <th>Total</th>
                <th>Status</th>
                <th>Date</th>
                <th>More</th>
              </tr>
            ) : (
              <tr>
                <th>S. no</th>
                <th>Job Title</th>
                <th>Amount</th>
                <th>Status</th>
                <th>Date</th>
                <th>More</th>
              </tr>
            )}
          </thead>
          <tbody>
            {user?.role === "loved-one"
              ? currentJobs?.map((a, i) => (
                  <tr key={i}>
                    <td className="text-center">{indexOfFirstJob + i + 1}</td>
                    <td>{a.title}</td>
                    <td>${a.budget}</td>
                    <td>${a.budget}</td>
                    <td className="text-center">
                      {a.status === "approved" ? (
                        <Badge pill bg="primary">
                          Approved
                        </Badge>
                      ) : a.status === "completed" ? (
                        <Badge pill bg="success">
                          Completed
                        </Badge>
                      ) : a.status === "rejected" ? (
                        <Badge pill bg="danger">
                          Rejected
                        </Badge>
                      ) : a.status === "hired" ? (
                        <Badge pill bg="info">
                          Hired
                        </Badge>
                      ) : (
                        <Badge pill bg="secondary">
                          Pending
                        </Badge>
                      )}
                    </td>
                    <td>{new Date(a.createdAt).toLocaleDateString()}</td>
                    <td className="text-center">
                      {!a.reviewed ? (
                        <div className="btn-div">
                          <button
                            onClick={() => openReviewModal(a)}
                            className="primary"
                          >
                            Give Review
                          </button>
                        </div>
                      ) : (
                        <div className="btn-div">
                          <button className="primary">Completed</button>
                        </div>
                      )}
                    </td>
                  </tr>
                ))
              : currentJobs?.map((a, i) => (
                  <tr key={i}>
                    <td className="text-center">{indexOfFirstJob + i + 1}</td>
                    <td><NavLink to={`/jobDetail/${a?.job?._id}`}>{a.job?.title}</NavLink></td>
                    <td>${a.amount}</td>
                    <td className="text-center">
                      {a.status === "applied" ? (
                        <Badge pill bg="primary">
                          Applied
                        </Badge>
                      ) : a.status === "rejected" ? (
                        <Badge pill bg="danger">
                          Rejected
                        </Badge>
                      ) : a.status === "inProgress" ? (
                        <Badge pill bg="secondary">
                          In Progress
                        </Badge>
                      ) : (
                        <Badge pill bg="success">
                          Finished
                        </Badge>
                      )}
                    </td>
                    <td>{new Date(a.createdAt).toLocaleDateString()}</td>
                    <td className="text-center">
                      {!a?.job?.reviewedCompanion ? (
                        <div className="btn-div">
                          <button
                            onClick={() => openReviewModalForCompanion(a)}
                            className="primary"
                          >
                            Give Review
                          </button>
                        </div>
                      ) : (
                        <div className="btn-div">
                          <button className="primary">Completed</button>
                        </div>
                      )}
                    </td>
                  </tr>
                ))}
          </tbody>
        </Table>
      </div>

      {/* Pagination */}
      <div className="d-flex justify-content-end mt-3">
        <Pagination className="custom-pagination">
          <Pagination.Prev
            onClick={() => currentPage > 1 && setCurrentPage(currentPage - 1)}
          />
          {Array.from({ length: totalPages }, (_, i) => (
            <Pagination.Item
              key={i + 1}
              active={i + 1 === currentPage}
              onClick={() => setCurrentPage(i + 1)}
            >
              {i + 1}
            </Pagination.Item>
          ))}
          <Pagination.Next
            onClick={() =>
              currentPage < totalPages && setCurrentPage(currentPage + 1)
            }
          />
        </Pagination>
      </div>
    </div>
  );
}

export default FinishedJobs;
