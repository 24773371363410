import { createSlice } from "@reduxjs/toolkit";
import * as Actions from "./actions";
import { addCaseWithLoading } from "../../helper";

const initialState = {
  isLoading: false,
  error: null,
  allJobs: null,
  postedJobs: null,
  jobWithApplicants: null,
  userReviews: null,
  userReviewsTotal: null,
  totalReviews: 0,
  chats: null,
  allChats: [],
  totalPage: null,
  getOneJob: null,
  singleJob: null,
  counter: null,
};

const jobSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {},

  extraReducers: (builder) => {
    addCaseWithLoading(builder, Actions.createJob, {
      onCompleted: (state, action) => {
        state.isLoading = false;
      },
      onPending: (state) => {
        state.isLoading = true;
        state.error = null;
      },
      onReject: (state, error) => {
        state.isLoading = false;
        state.error = error?.error;
      },
    });
    addCaseWithLoading(builder, Actions.getAllJobs, {
      onCompleted: (state, action) => {
        state.isLoading = false;
        state.allJobs = action.payload.data;
      },
      onPending: (state) => {
        state.isLoading = true;
        state.error = null;
      },
      onReject: (state, error) => {
        state.isLoading = false;
        state.error = error?.error;
      },
    });
    addCaseWithLoading(builder, Actions.getSingleJob, {
      onCompleted: (state, action) => {
        state.isLoading = false;
        state.singleJob = {
          ...action.payload.data._doc,
          allreadyApplied: action.payload.data.allreadyApplied,
        };
      },
      onPending: (state) => {
        state.isLoading = true;
        state.error = null;
      },
      onReject: (state, error) => {
        state.isLoading = false;
        state.error = error?.error;
      },
    });
    addCaseWithLoading(builder, Actions.getOneJob, {
      onCompleted: (state, action) => {
        // console.log(action.payload);

        state.isLoading = false;
        state.getOneJob = action.payload.data;
      },
      onPending: (state) => {
        state.isLoading = true;
        state.error = null;
      },
      onReject: (state, error) => {
        state.isLoading = false;
        state.error = error?.error;
      },
    });
    addCaseWithLoading(builder, Actions.getAllFrontendJobs, {
      onCompleted: (state, action) => {
        state.isLoading = false;
        state.allJobs = action.payload.data.jobs;
        state.totalPage = action.payload.data.totalPages;
      },
      onPending: (state) => {
        state.isLoading = true;
        state.error = null;
      },
      onReject: (state, error) => {
        state.isLoading = false;
        state.error = error?.error;
      },
    });
    addCaseWithLoading(builder, Actions.getPostedJobs, {
      onCompleted: (state, action) => {
        state.isLoading = false;
        state.postedJobs = action.payload.data;
      },
      onPending: (state) => {
        state.isLoading = true;
        state.error = null;
      },
      onReject: (state, error) => {
        state.isLoading = false;
        state.error = error?.error;
      },
    });
    addCaseWithLoading(builder, Actions.getSingleJobWithApplicants, {
      onCompleted: (state, action) => {
        state.isLoading = false;
        state.jobWithApplicants = action.payload.data;
      },
      onPending: (state) => {
        state.isLoading = true;
        state.error = null;
      },
      onReject: (state, error) => {
        state.isLoading = false;
        state.error = error?.error;
      },
    });

    addCaseWithLoading(builder, Actions.getUserReviews, {
      onCompleted: (state, action) => {
        console.log(action);
        state.isLoading = false;
        state.userReviews = action.payload.data;
        state.userReviewsTotal = action.payload?.pagination?.totalPages;
        state.totalReviews = action.payload?.pagination?.totalReviews;
      },
      onPending: (state) => {
        state.isLoading = true;
        state.error = null;
      },
      onReject: (state, error) => {
        state.isLoading = false;
        state.error = error?.error;
      },
    });

    addCaseWithLoading(builder, Actions.getJobsWithApplicantsInProgress, {
      onCompleted: (state, action) => {
        state.isLoading = false;
        state.chats = action.payload.data;
      },
      onPending: (state) => {
        state.isLoading = true;
        state.error = null;
      },
      onReject: (state, error) => {
        state.isLoading = false;
        state.error = error?.error;
      },
    });

    addCaseWithLoading(builder, Actions.getCompanionChats, {
      onCompleted: (state, action) => {
        console.log(action.payload.data);
        state.isLoading = false;
        state.chats = action.payload.data;
        state.counter = action.payload.data?.chatHead;
      },
      onPending: (state) => {
        state.isLoading = true;
        state.error = null;
      },
      onReject: (state, error) => {
        state.isLoading = false;
        state.error = error?.error;
      },
    });

    addCaseWithLoading(builder, Actions.getChats, {
      onCompleted: (state, action) => {
        let c = [...state.allChats];
        let check = false;
        console.log(action.payload.data);
        check = c.filter(
          (a) => a.project_id === action.payload.data.project_id
        )[0]
          ? true
          : false;

        if (!check) {
          c = [...state.allChats, action.payload.data];
        } else {
          const index = c.findIndex(
            (item) =>
              item.project_id ===
              c.filter(
                (a) => a.project_id === action.payload.data.project_id
              )[0].project_id
          );

          c.splice(index, 1, action.payload.data);
        }

        state.isLoading = false;
        state.allChats = c;
      },
      onPending: (state) => {
        state.isLoading = true;
        state.error = null;
      },
      onReject: (state, error) => {
        state.isLoading = false;
        state.error = error?.error;
      },
    });
    // addCaseWithLoading(builder, Actions.getChatHead, {
    //   onCompleted: (state, action) => {
    //     // console.log(action.payload);
    //     state.isLoading = false;
    //     // state.chats = action.payload.data;
    //     // state.counter = action.payload.data?.chatHead;
    //   },
    //   onPending: (state) => {
    //     state.isLoading = true;
    //     state.error = null;
    //   },
    //   onReject: (state, error) => {
    //     state.isLoading = false;
    //     state.error = error?.error;
    //   },
    // });
  },
});

export default jobSlice.reducer;
