import React, { useEffect, useState } from "react";
import { Table, Pagination } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { getTransaction } from "../../../redux/transactionSlice/actions";

function Transaction() {
  const dispatch = useDispatch();
  const payment = useSelector((state) => state.transaction.transaction);
  const user = useSelector((state) => state.auth.user);

  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 10;

  const getData = () => {
    if (user) {
      dispatch(getTransaction({})).then((res) => {
        if (res?.payload?.status === "success") {
          // Handle success if needed
        }
      });
    }
  };

  useEffect(() => {
    getData();
  }, [user]);

  // Calculate current transactions based on the current page
  const indexOfLastTransaction = currentPage * itemsPerPage;
  const indexOfFirstTransaction = indexOfLastTransaction - itemsPerPage;
  const currentTransactions = payment?.slice(indexOfFirstTransaction, indexOfLastTransaction);

  // Total pages
  const totalPages = Math.ceil((payment?.length || 0) / itemsPerPage);

  return (
    <div className="jobHistoryMain p-3">
      <div className="tableDiv">
        <Table bordered hover>
          <thead>
            <tr>
              <th>S. no</th>
              <th>Amount</th>
              <th>Currency</th>
              <th>Description</th>
              <th>Date</th>
            </tr>
          </thead>
          <tbody>
            {currentTransactions?.map((a, i) => (
              <tr key={i}>
                <td className="text-center">{indexOfFirstTransaction + i + 1}</td>
                <td>${a.amount}</td>
                <td>{a.currency}</td>
                <td>{a.description ? a.description : "Purchased Credits"}</td>
                <td>{a.createdAt ? a.createdAt.split("T")[0] : "-"}</td>
              </tr>
            ))}
          </tbody>
        </Table>
      </div>

      {/* Pagination */}
      <div className="d-flex justify-content-end mt-3">
        <Pagination className="custom-pagination">
          <Pagination.Prev
            onClick={() => currentPage > 1 && setCurrentPage(currentPage - 1)}
          />
          {Array.from({ length: totalPages }, (_, i) => (
            <Pagination.Item
              key={i + 1}
              active={i + 1 === currentPage}
              onClick={() => setCurrentPage(i + 1)}
            >
              {i + 1}
            </Pagination.Item>
          ))}
          <Pagination.Next
            onClick={() => currentPage < totalPages && setCurrentPage(currentPage + 1)}
          />
        </Pagination>
      </div>
    </div>
  );
}

export default Transaction;
