import { useState } from "react";
import { Col, Row } from "react-bootstrap";
import {
  forgotPassword,
  getUserDetail,
  resetPassword,
  verifyOtpReset,
} from "../../redux/authSlice/actions";
import { useDispatch } from "react-redux";

function ChangePassword() {
  const [password, setPassword] = useState("");
  const [email, setEmail] = useState("");
  const [otp, setOtp] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [step1, setStep1] = useState(true);
  const [step2, setStep2] = useState(false);
  const [step3, setStep3] = useState(false);
  const dispatch = useDispatch();
  console.log(otp);

  const updatePassword = () => {
    let data = {
      password: password,
      confirmPassword: confirmPassword,
    };
    let resetToken = localStorage.getItem("resetToken");

    dispatch(resetPassword({ payload: data, resetToken: resetToken })).then((res) => {
      if (res.payload?.status === "success") {
        dispatch(getUserDetail());
        setPassword("")
        setConfirmPassword("")
        setEmail("")
        setOtp("")
        setStep1(true)
        setStep2(false)
        setStep3(true)
      }
    });
  };
  const sendEmail = () => {
    let data = {
      email: email,
    };

    dispatch(forgotPassword({ payload: data })).then((res) => {
      if (res.payload?.status === "success") {
        setStep1(false);
        setStep2(true);
      }
    });
  };
  const sendOtp = () => {
    let data = {
      otp: otp,
    };

    dispatch(verifyOtpReset({ payload: data })).then((res) => {
      if (res.payload?.status === "success") {
        let token = res.payload?.data?.token;
        localStorage.setItem("resetToken", token);
        setStep1(false);
        setStep2(false);
        setStep3(true);
      }
    });
  };

  return (
    // <form>
    <div className="idenityVerify shadow p-3">
      {step1 ? (
        <div className="UploadDivMain">
          <Row>
            <Col md={12}>
              <div className="inputDiv">
                <label>Email</label>
                <input
                  type="text"
                  placeholder="Enter Your Email"
                  onChange={(e) => {
                    setEmail(e.target.value);
                  }}
                  value={email}
                  required
                />
              </div>
            </Col>
          </Row>
          <div className="btn-div">
            <button className="primary" onClick={sendEmail}>
              Continue
            </button>
          </div>
        </div>
      ) : step2 ? (
        <div className="UploadDivMain">
          <Row>
            <Col md={12}>
              <div className="inputDiv">
                <label>Otp</label>
                <input
                  type="text"
                  placeholder="Enter Your Otp"
                  onChange={(e) => {
                    setOtp(e.target.value);
                  }}
                  value={otp}
                  required
                />
              </div>
            </Col>
          </Row>
          <div className="btn-div">
            <button className="primary" onClick={sendOtp}>
              Continue
            </button>
          </div>
        </div>
      ) : (
        <div className="UploadDivMain">
          <Row>
            <Col md={6}>
              <div className="inputDiv">
                <label>Password</label>
                <input
                  type="text"
                  placeholder="Enter Your Password"
                  onChange={(e) => {
                    setPassword(e.target.value);
                  }}
                  value={password}
                  required
                />
              </div>
            </Col>
            <Col md={6}>
              <div className="inputDiv">
                <label>Confirm Password</label>
                <input
                  type="text"
                  placeholder="Enter Your Confirm Password"
                  onChange={(e) => {
                    setConfirmPassword(e.target.value);
                  }}
                  value={confirmPassword}
                  required
                />
              </div>
            </Col>
          </Row>
          <div className="btn-div">
            <button className="primary" onClick={() => updatePassword()}>
              Submit
            </button>

            {/* <button className="primary" onClick={getReport}>
            Get Report
          </button> */}
          </div>
        </div>
      )}
    </div>
    // </form>
  );
}
export default ChangePassword;
