// // AutocompleteInput.js
// import React, { useEffect, useState } from 'react';
// import axios from 'axios';

// const AutocompleteInput = (props) => {
//     const [query, setQuery] = useState('');
//     const [suggestions, setSuggestions] = useState([]);
//     const [locationDetails, setLocationDetails] = useState({
//         formatted: '',
//         city: '',
//         zip: '',
//         lat: null,
//         lng: null,
//     });

//     const handleInputChange = async (e) => {
//         const value = e.target.value;
//         setQuery(value);

//         if (value.length > 2) {
//             try {
//                 const response = await axios.get('https://api.opencagedata.com/geocode/v1/json', {
//                     params: {
//                         q: value,
//                         key: 'effd354389f74e269bbdfd01f4deeb26', // Replace with your OpenCage API key
//                         limit: 5, // Limit the number of suggestions
//                     },
//                 });

//                 const results = response.data.results;
//                 const places = results.map((result) => {
//                     const components = result.components;
//                     return {
//                         formatted: result.formatted,
//                         city: components.city || components.town || components.village || '',
//                         zip: components.postcode || '',
//                         state: components.state || '',
//                         country: components.country || '',
//                         lat: result.geometry.lat,
//                         lng: result.geometry.lng,
//                     };
//                 });
//                 setSuggestions(places);
//             } catch (error) {
//                 console.error('Error fetching suggestions:', error);
//             }
//         } else {
//             setSuggestions([]);
//         }
//     };

//     const handleSuggestionClick = (suggestion) => {
//         setQuery(suggestion.formatted);
//         setLocationDetails({
//             formatted: suggestion.formatted,
//             city: suggestion.city,
//             zip: suggestion.zip,
//             state: suggestion.state,
//             lat: suggestion.lat,
//             lng: suggestion.lng,
//         });
//         console.log(suggestion)
//         props.setFormData({
//             ...props.formData,
//             city: suggestion.city,
//             state: suggestion.state,
//             zipcode: suggestion.zip,
//             address: suggestion.formatted,
//             country: suggestion.country,
//             latitude: suggestion.lat,
//             longitude: suggestion.lng,
//         })
//         setSuggestions([]);
//     };

//     useEffect(() => {
//         setQuery(props.formData.address)
//     }, [props.formData])

//     return (
//         <div style={{ position: "relative" }}>
//             <div className='inputDiv'>
//                 <label>{props.title}</label>
//                 <input
//                     type="text"
//                     value={query}
//                     onChange={handleInputChange}
//                     placeholder="Type a location"
//                     style={{ width: '100%', padding: '10px', fontSize: '16px' }}
//                 />
//             </div>
//             {suggestions.length > 0 && (
//                 <ul style={{ position: "absolute", listStyleType: 'none', paddingLeft: 0, background: '#fff', border: '1px solid #ddd', zIndex: 9 }}>
//                     {suggestions.map((suggestion, index) => (
//                         <li
//                             key={index}
//                             onClick={() => handleSuggestionClick(suggestion)}
//                             style={{ padding: '10px', cursor: 'pointer', borderBottom: '1px solid #eee' }}
//                         >
//                             {suggestion.formatted}
//                         </li>
//                     ))}
//                 </ul>
//             )}
//         </div>
//     );
// };

// export default AutocompleteInput;
import React, { useEffect, useState } from "react";
import axios from "axios";

const AutocompleteInput = (props) => {
  const [query, setQuery] = useState("");
  const [suggestions, setSuggestions] = useState([]);
  const [locationDetails, setLocationDetails] = useState({
    formatted: "",
    city: "",
    zip: "",
    lat: null,
    lng: null,
  });

  //   const handleInputChange = async (e) => {
  //     const value = e.target.value;
  //     setQuery(value);

  //     if (value.length > 2) {
  //       try {
  //         const response = await axios.get(
  //           "https://maps.googleapis.com/maps/api/place/autocomplete/json",
  //           {
  //             params: {
  //               input: value,
  //               key: "AIzaSyBfDIBDg2SAKLAF8Y3NU--oe5nNv2-uh2Y", // Your Google API key
  //               types: "geocode", // This can be adjusted based on your needs
  //               components: "country:usa", // Limit results to a specific country, adjust if needed
  //             },
  //           }
  //         );

  //         const predictions = response.data.predictions;
  //         const places = predictions.map((prediction) => {
  //           return {
  //             formatted: prediction.description,
  //             place_id: prediction.place_id,
  //           };
  //         });

  //         setSuggestions(places);
  //       } catch (error) {
  //         console.error("Error fetching suggestions:", error);
  //       }
  //     } else {
  //       setSuggestions([]);
  //     }
  //   };

  // const handleSuggestionClick = async (suggestion) => {
  //   try {
  //     const detailsResponse = await axios.get(
  //       "https://maps.googleapis.com/maps/api/place/details/json",
  //       {
  //         params: {
  //           place_id: suggestion.place_id,
  //           key: "AIzaSyBfDIBDg2SAKLAF8Y3NU--oe5nNv2-uh2Y", // Your Google API key
  //         },
  //       }
  //     );

  //     const result = detailsResponse.data.result;
  //     const components = result.address_components;

  //     const getComponent = (type) => {
  //       const component = components.find((comp) => comp.types.includes(type));
  //       return component ? component.long_name : "";
  //     };

  //     const lat = result.geometry.location.lat;
  //     const lng = result.geometry.location.lng;

  //     const formattedAddress = result.formatted_address;
  //     const city =
  //       getComponent("locality") || getComponent("administrative_area_level_2");
  //     const state = getComponent("administrative_area_level_1");
  //     const zip = getComponent("postal_code");
  //     const country = getComponent("country");

  //     setQuery(formattedAddress);
  //     setLocationDetails({
  //       formatted: formattedAddress,
  //       city,
  //       zip,
  //       state,
  //       lat,
  //       lng,
  //     });

  //     // Pass the selected location to the parent form
  //     props.setFormData({
  //       ...props.formData,
  //       city,
  //       state,
  //       zipcode: zip,
  //       zip: zip,
  //       address: formattedAddress,
  //       country,
  //       latitude: lat,
  //       longitude: lng,
  //     });

  //     setSuggestions([]);
  //   } catch (error) {
  //     console.error("Error fetching place details:", error);
  //   }
  // };


  const handleSuggestionClick = async (suggestion) => {
    const placesService = new window.google.maps.places.PlacesService(document.createElement('div'));

    placesService.getDetails(
      {
        placeId: suggestion.place_id,
      },
      (place, status) => {
        if (status === window.google.maps.places.PlacesServiceStatus.OK) {
          const components = place.address_components;

          const getComponent = (type) => {
            const component = components.find(comp => comp.types.includes(type));
            return component ? component.long_name : '';
          };

          const lat = place.geometry.location.lat();
          const lng = place.geometry.location.lng();
          const formattedAddress = place.formatted_address;
          const city = getComponent('locality') || getComponent('administrative_area_level_2');
          const state = getComponent('administrative_area_level_1');
          const zip = getComponent('postal_code');
          const country = getComponent('country');

          setQuery(formattedAddress);
          setLocationDetails({
            formatted: formattedAddress,
            city,
            zip,
            zipcode: zip,
            state,
            lat,
            lng,
          });

          // Pass the selected location to the parent form
          props.setFormData({
            ...props.formData,
            city,
            state,
            zipcode: zip,
            zip: zip,
            address: formattedAddress,
            country,
            latitude: lat,
            longitude: lng,
          });

          setSuggestions([]);
        } else {
          console.error('Error fetching place details:', status);
        }
      }
    );
  };

  const handleInputChange = async (e) => {
    const value = e.target.value;
    setQuery(value);

    if (value.length > 2) {
      const autocompleteService = new window.google.maps.places.AutocompleteService();

      autocompleteService.getPlacePredictions(
        {
          input: value,
          types: ['geocode'], // Change as needed
        },
        (predictions, status) => {
          if (status === window.google.maps.places.PlacesServiceStatus.OK) {
            const places = predictions.map((prediction) => {
              return {
                formatted: prediction.description,
                place_id: prediction.place_id,
              };
            });

            setSuggestions(places);
          } else {
            console.error('Autocomplete failed:', status);
          }
        }
      );
    } else {
      setSuggestions([]);
    }
  };

  useEffect(() => {
    setQuery(props.formData.address);
  }, [props.formData]);

  return (
    <div style={{ position: "relative" }}>
      <div className="inputDiv">
        <label>{props.title}</label>
        <input
          type="text"
          value={query}
          onChange={handleInputChange}
          placeholder="Type a location"
          style={{ width: "100%", padding: "10px", fontSize: "16px" }}
        />
      </div>
      {suggestions.length > 0 && (
        <ul
          style={{
            position: "absolute",
            listStyleType: "none",
            paddingLeft: 0,
            background: "#fff",
            border: "1px solid #ddd",
            zIndex: 9,
          }}
        >
          {suggestions.map((suggestion, index) => (
            <li
              key={index}
              onClick={() => handleSuggestionClick(suggestion)}
              style={{
                padding: "10px",
                cursor: "pointer",
                borderBottom: "1px solid #eee",
              }}
            >
              {suggestion.formatted}
            </li>
          ))}
        </ul>
      )}
    </div>
  );
};

export default AutocompleteInput;
