import React, { useEffect, useState } from 'react'
import { Col, Modal, Row } from "react-bootstrap";
import './SubscriptionScreen.scss'
import { Container } from 'react-bootstrap'
import { useDispatch, useSelector } from 'react-redux'
import StripeModal from '../../Components/StripeModal/StripeModal'
import { getUserDetail, updateUserData } from '../../redux/authSlice/actions';

function SubcriptionScreen(props) {
  let dispatch = useDispatch();
  let { user } = useSelector((state) => state.auth)
  let [promoCode, setPromoCode] = useState("");
  const [show, setShow] = useState(false)
  const [selectedAmount, setselectedAmount] = useState(0)
  const [codeModal, setCodeModal] = useState(false);

  const handleCloseCodeModal = () => setCodeModal(false);
  const handleClose = () => setShow(false)
  const handleShow = (amount) => {
    setselectedAmount(amount)
    setShow(true)
  }
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [])

  const [showModal, setShowModal] = useState(false);

  const handleCloseModal = () => setShowModal(false);
  const handleShowModal = () => setShowModal(true);

  useEffect(() => {
    if (user) {
      handleShowModal()
    }
  }, [user])
  const promocode = () => {
    setCodeModal(false)
    if (promoCode == "12345") {
      let data = {
        identityVerified: true,
        subscribed: true
      };

      dispatch(updateUserData({ payload: data })).then((res) => {
        if (res.payload?.status === "success") {
          dispatch(getUserDetail());
        }
      });
    }
  }
  return (
    <div className='subscriptionScreenMain'>

      <Container>
        {/* <Modal centered show={codeModal} onHide={handleCloseCodeModal}>
        <Modal.Body>
          <Row>
            <Col md={12} className="mt-3">
              <div className="inputDiv">
                <label>Promo Code</label>
                <input
                  type="text"
                  placeholder="Enter Your Promo Code 12345"
                  onChange={(e) => {
                    setPromoCode(e.target.value)
                  }}
                  value={promoCode}
                  required
                />
              </div>
            </Col>
          </Row>
          <div className='btn-div text-center mt-3'>
            <button className='primary' onClick={promocode}>Continue</button>
          </div>
        </Modal.Body>
      </Modal> */}
        {/* {props.modal && user &&
          <div className='mb-3'>
            <div className='heading text-center'>
              <h5>Step 1</h5>
              <h6>Welcome to Kaytree!</h6>
            </div>
            <div className='text-div text-center mt-2'>
              <p>Proceed with Subscription to Unlock Benefits.</p>
            </div>
          </div>
        } */}

        <h1 class="text-center pricing-table-title">Step 1 of 4: Sign Up to Begin</h1>
        {/* <h1 class="text-center pricing-table-title">Sign up now to unlock benefits</h1> */}
        {/* <h5 class="text-center pricing-table-subtitle">  </h5> */}
        <div class="row justify-content-center">

          {user?.role === "loved-one" ?
            <>
              <div class="col-md-4">
                <div class="card pricing-card pricing-card-highlighted  pricing-plan-pro">
                  <div class="card-body">
                    <h3 class="pricing-plan-cost sales">SALE</h3>
                    <i class="fas fa-unlock-alt pricing-plan-icon"></i>
                    <h3 class="pricing-plan-cost ml-auto">$79/Early Bird Sale</h3>
                    <ul class="pricing-plan-features">
                      <li>50 Free Credits</li>
                      <li>New User Background Check</li>
                      <li>Unlimited Job Postings</li>
                    </ul>
                    <div className='btn-div'>
                      {user?.subscribed ?
                        <button>Already Subscribed</button>
                        :
                        <button onClick={() => handleShow(79)}>Purchase</button>
                      }
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-md-4">
                <div class="card pricing-card pricing-card-highlighted  pricing-plan-pro">
                  <div class="card-body">
                    <i class="fas fa-unlock-alt pricing-plan-icon"></i>
                    <h3 class="pricing-plan-cost ml-auto">$99/One Time Startup Fee</h3>
                    <ul class="pricing-plan-features">
                      <li>50 Free Credits</li>
                      <li>New User Background Check</li>
                      <li>Unlimited Job Postings</li>
                    </ul>
                    <div className='btn-div'>
                      {user?.subscribed ?
                        <button>Already Subscribed</button>
                        :
                        <button onClick={() => handleShow(99)}>Purchase</button>
                      }
                    </div>
                  </div>
                </div>
              </div>
            </>
            : (
              <>
                <div class="col-md-4">
                  <div class="card pricing-card pricing-card-highlighted  pricing-plan-pro">
                    <div class="card-body">
                      <i class="fas fa-unlock-alt pricing-plan-icon"></i>
                      <h3 class="pricing-plan-cost ml-auto">$29/One Time Startup Fee</h3>
                      <ul class="pricing-plan-features">
                        <li>
                          <h6>Includes:</h6>
                        </li>
                        <li>New User Background Check</li>
                        <li>Apply to Unlimited Jobs</li>
                      </ul>
                      <div className='btn-div'>
                        {user?.subscribed ? (
                          <button>Already Subscribed</button>
                        ) : (
                          <button onClick={() => handleShow(29)}>Purchase</button>
                        )}
                      </div>
                    </div>
                  </div>
                  <div class="card pricing-card pricing-card-highlighted  pricing-plan-pro">
                    <div class="promo-card-body card-body">
                      <Row>
                        <Col md={12} className="mt-3">
                          <div className="inputDiv">
                            {/* <label>Promo Code</label> */}
                            <input
                              type="text"
                              placeholder="Enter Your Promo Code 12345"
                              onChange={(e) => {
                                setPromoCode(e.target.value)
                              }}
                              value={promoCode}
                              required
                            />
                          </div>
                        </Col>
                      </Row>
                      <div className='btn-div text-center mt-3'>
                        <button className='primary' onClick={promocode}>Continue</button>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="col-md-4">
                  <div class="card pricing-card pricing-card-highlighted  pricing-plan-pro">
                    <div class="card-body">
                      <h3 class="pricing-plan-cost sales">SALE</h3>
                      <i class="fas fa-unlock-alt pricing-plan-icon"></i>
                      <h3 class="pricing-plan-cost ml-auto">Only $19 / Early Adopter Special</h3>
                      <ul class="pricing-plan-features">
                        <ul class="pricing-plan-features">
                          <li>
                            <h6>Includes:</h6>
                          </li>
                          <li>New User Background Check</li>
                          <li>Apply to Unlimited Jobs</li>
                        </ul>
                      </ul>
                      <div className='btn-div'>
                        {user?.subscribed ? (
                          <button>Already Subscribed</button>
                        ) : (
                          <button onClick={() => handleShow(19)}>Purchase</button>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </>
            )}
        </div>
      </Container>

      <StripeModal show={show} handleClose={handleClose} amount={selectedAmount} />
    </div >
  )
}

export default SubcriptionScreen