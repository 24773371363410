import React, { useEffect, useState } from "react";
import { Col, Modal, Row } from "react-bootstrap";
import {
  getUserDetail,
  updateUserData,
  upload,
} from "../../../redux/authSlice/actions";
import { useDispatch, useSelector } from "react-redux";
import axios from "axios";

function IdentityVerify(props) {
  let [idFront, setIdFront] = useState("");
  let [idBack, setIdBack] = useState("");
  

  let user = useSelector((state) => state.auth.user);

  const [formData, setFormData] = useState({
    first_name: '',
    last_name: '',
    email: '',
    ssn: ''
  });

  let dispatch = useDispatch();

  const [showModal2, setShowModal2] = useState(false);
 
  const handleCloseModal2 = () => setShowModal2(false);
  const handleShowModal2 = () => setShowModal2(true);

  const uploadImage = (e, name) => {
    let formData = new FormData();
    formData.append("image", e.target.files[0]);
    dispatch(upload({ payload: formData, upload: true })).then((res) => {
      if (res.payload?.status === "success") {
        let image = res.payload?.data?.url;
        if (name === "front") {
          setIdFront(image);
        } else {
          setIdBack(image);
        }
      }
    });
  };

  const handleSubmitCandidate = async () => {
    try {
      // Retrieve the token from local storage
      const token = localStorage.getItem('token'); // Ensure 'token' is the key used to store the token

      // Set up the headers with the bearer token
      const config = {
        headers: {
          'Authorization': `Bearer ${token}`,
          'Content-Type': 'application/json',
        },
      };

      // Create candidate
      const candidateResponse = await axios.post('https://kaytreebackend-63da29f5d5cb.herokuapp.com/api/v1/background/create-candidate', formData, config);
      console.log('Candidate created:', candidateResponse.data);

      // Send invitation
      const invitationResponse = await axios.post('https://kaytreebackend-63da29f5d5cb.herokuapp.com/api/v1/background/send-invitation', {
        candidateId: candidateResponse.data.id,
        email: formData.email,
      }, config);
      window.location.reload()
    } catch (error) {
      console.error('Error:', error);
      alert('Error occurred. Please check the console for details.');
    }
  };


  const updateUser = (e) => {
    e.preventDefault();
    let data = {
      id_front: idFront,
      id_back: idBack,
    };
    dispatch(updateUserData({ payload: data })).then((res) => {
      if (res.payload?.status === "success") {
        handleSubmitCandidate()
        dispatch(getUserDetail());
      }
    });
  };

  useEffect(() => {
    if (user) {
      setIdBack(user?.id_back);
      setIdFront(user?.id_front);
      setFormData({
        first_name: "Nick",
        last_name: "Jonas",
        email: user?.email,
        ssn: ''
      })
    }
  }, [user]);

  // const getReport = async () => {
  //   try {
  //     // Retrieve the token from local storage
  //     const token = localStorage.getItem('token'); // Ensure 'token' is the key used to store the token

  //     // Set up the headers with the bearer token
  //     const config = {
  //       headers: {
  //         'Authorization': `Bearer ${token}`,
  //         'Content-Type': 'application/json',
  //       },
  //     };

  //     // Create candidate
  //     const candidateResponse = await axios.get('https://kaytreebackend-63da29f5d5cb.herokuapp.com/api/v1/background/getReport/187b38fc3fcad14b13f7ee93', config);
  //     console.log('Candidate created:', candidateResponse.data);

  //   } catch (error) {
  //     console.error('Error:', error);
  //     alert('Error occurred. Please check the console for details.');
  //   }
  // }

  const [showModal, setShowModal] = useState(false);

  const handleCloseModal = () => setShowModal(false);
  const handleShowModal = () => setShowModal(true);

  useEffect(() => {
    if (user && !user?.checkrId) {
      handleShowModal()
    } else if (user && user?.checkrId) {
      handleShowModal2()
    }
  }, [user])
  
  return (
    <>

      {props.modal && user && user?.checkrId &&
        <Modal centered show={showModal2} onHide={handleCloseModal2}>
          <Modal.Body>
            <div className='heading text-center'>
              <h5>Step 4</h5>
              <h6>Check your email and complete the background check.</h6>
            </div>
            <div className='text-div text-center mt-2'>
              <p>You will soon receive an email from Checkr to complete the back ground check. Once approved you will receive a confirmation email or text. (It may take 2-3 days).</p>
              <p>Your account will then be ready to use.</p>
            </div>

            <div className='btn-div text-center mt-3'>
              <button className='secondary' onClick={handleCloseModal2}>Close</button>
            </div>
          </Modal.Body>
        </Modal>
      }


      {props.modal && user && !user?.checkrId &&
        <div className="mb-3">
          <div className='heading text-center'>
            <h5>Step 3</h5>
            <h6>Upload Legal Photo ID</h6>
          </div>
          <div className='text-div text-center mt-2'>
            <p>Enter yourt SSN to begin with the Background Check</p>
          </div>
        </div>
      }
     
      <form onSubmit={(e) => updateUser(e)}>
        <div className="idenityVerify shadow p-3">
          <h1 class="text-center pricing-table-title">Step 3 of 4: Upload photo ID and begin the background check</h1>
          <div className="heading text-center">
            <h5>Adults require a background check to provide a safe environment for all users</h5>
          </div>

          <div className="UploadDivMain">
            <Row>
              <Col md={7}>
                <div
                  className="uploadView"
                  style={idFront ? { backgroundImage: `url("${idFront}")` } : {}}
                >
                  {!user?.id_front && (
                    <div className="heading">
                      <h6>
                        Upload File
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="16"
                          height="16"
                          fill="currentColor"
                          class="bi bi-cloud-arrow-up-fill"
                          viewBox="0 0 16 16"
                        >
                          <path d="M8 2a5.53 5.53 0 0 0-3.594 1.342c-.766.66-1.321 1.52-1.464 2.383C1.266 6.095 0 7.555 0 9.318 0 11.366 1.708 13 3.781 13h8.906C14.502 13 16 11.57 16 9.773c0-1.636-1.242-2.969-2.834-3.194C12.923 3.999 10.69 2 8 2m2.354 5.146a.5.5 0 0 1-.708.708L8.5 6.707V10.5a.5.5 0 0 1-1 0V6.707L6.354 7.854a.5.5 0 1 1-.708-.708l2-2a.5.5 0 0 1 .708 0z" />
                        </svg>
                      </h6>
                    </div>
                  )}
                  {!user?.id_front && (
                    <div className="fileInput">
                      <input
                        type="file"
                        onChange={(e) => uploadImage(e, "front")}
                      />
                    </div>
                  )}
                </div>
              </Col>
              <Col md={5}>
                {!user?.id_front ? (
                  <div className="textDiv">
                    <div className="heading text-center">
                      <h5>
                        Upload Front Side
                        <br /> of your ID
                      </h5>
                    </div>
                  </div>
                ) : (
                  <div className="textDiv">
                    <div className="btn-div">
                      <button>
                        Re-upload
                        <div className="fileInput">
                          <input
                            type="file"
                            onChange={(e) => uploadImage(e, "front")}
                          />
                        </div>
                      </button>
                    </div>
                  </div>
                )}
              </Col>
              <Col md={7}>
                <div
                  className="uploadView mt-3"
                  style={idBack ? { backgroundImage: `url("${idBack}")` } : {}}
                >
                  {!user?.id_back && (
                    <div className="heading">
                      <h6>
                        Upload File
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="16"
                          height="16"
                          fill="currentColor"
                          class="bi bi-cloud-arrow-up-fill"
                          viewBox="0 0 16 16"
                        >
                          <path d="M8 2a5.53 5.53 0 0 0-3.594 1.342c-.766.66-1.321 1.52-1.464 2.383C1.266 6.095 0 7.555 0 9.318 0 11.366 1.708 13 3.781 13h8.906C14.502 13 16 11.57 16 9.773c0-1.636-1.242-2.969-2.834-3.194C12.923 3.999 10.69 2 8 2m2.354 5.146a.5.5 0 0 1-.708.708L8.5 6.707V10.5a.5.5 0 0 1-1 0V6.707L6.354 7.854a.5.5 0 1 1-.708-.708l2-2a.5.5 0 0 1 .708 0z" />
                        </svg>
                      </h6>
                    </div>
                  )}

                  {!user?.id_back && (
                    <div className="fileInput">
                      <input type="file" onChange={(e) => uploadImage(e, "back")} />
                    </div>
                  )}
                </div>
              </Col>
              <Col md={5}>
                {!user?.id_back ? (
                  <div className="textDiv">
                    <div className="heading text-center">
                      <h5>
                        Upload Back Side
                        <br /> of your ID
                      </h5>
                    </div>
                  </div>
                ) : (
                  <div className="textDiv">
                    <div className="btn-div">
                      <button>
                        Re-upload
                        <div className="fileInput">
                          <input
                            type="file"
                            onChange={(e) => uploadImage(e, "back")}
                          />
                        </div>
                      </button>
                    </div>
                  </div>
                )}
              </Col>

              <Col md={12} className="mt-3">
                <div className="inputDiv">
                  <label>SSN</label>
                  <input
                    type="text"
                    placeholder="Enter Your SSN to begin the Back Ground Check"
                    onChange={(e) => {
                      let obj = { ...formData }
                      obj.ssn = e.target.value
                      setFormData(obj)
                    }}
                    required
                  />
                </div>
              </Col>

              <Col md={6} className="mt-3">
                <div className="inputDiv">
                  <label>First Name</label>
                  <input
                    type="text"
                    placeholder="Enter Your First Name"
                    onChange={(e) => {
                      let obj = { ...formData }
                      obj.first_name = e.target.value
                      setFormData(obj)
                    }}
                    required
                  />
                </div>
              </Col>
              <Col md={6} className="mt-3">
                <div className="inputDiv">
                  <label>Last Name</label>
                  <input
                    type="text"
                    placeholder="Enter Your Last Name"
                    onChange={(e) => {
                      let obj = { ...formData }
                      obj.last_name = e.target.value
                      setFormData(obj)
                    }}
                    required
                  />
                </div>
              </Col>
            </Row>
          </div>

          <div className="btn-div">
            <button className="primary" type="submit">
              Submit
            </button>

            {/* <button className="primary" onClick={getReport}>
            Get Report
          </button> */}
          </div>
          {/* <div className="heading text-center mt-4">
            <h5>OR</h5>
          </div>
          <div className="btn-div">
            <button className="primary" onClick={() => setCodeModal(true)}>
              Enter Promo Code
            </button>
          </div> */}
        </div>
      </form>
    </>
  );
}

export default IdentityVerify;
