import React, { useEffect, useState } from 'react'
import { Modal } from 'react-bootstrap'

import {
    CardElement,
    Elements,
    useElements,
    useStripe,
} from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import { useDispatch, useSelector } from 'react-redux';
import { purchaseSubscription } from '../../redux/userSlice/actions';

const stripePromise = loadStripe(
    "pk_test_51Q7gszFM2Z7VvSwOVWs1S09IlCM56SMvlqHn7ENKJ1jCexAZSJaZmZd2zWuGVtlJiZa3EG6nluQaoK1LfCvsCAxZ00ejePKLak"
);

const CheckoutForm = ({amount}) => {
    const stripe = useStripe();
    const elements = useElements();
    const [isProcessing, setIsProcessing] = useState(false);
    const [paymentMessage, setPaymentMessage] = useState("");
    let [dollar, setDollar] = useState(dollarsToCents(amount));
    let [credits, setCredits] = useState(amount);

    let user = useSelector((state) => state.auth.user);

    let dispatch = useDispatch();

    useEffect(() => {
        setDollar(dollarsToCents(amount));
        setCredits(amount);
      }, [amount]);

    // useEffect(()=>{
    //     if(user){
    //         if(user?.role === "loved-one"){
    //             setCredits(amount);
    //             setDollar(dollarsToCents(99));
    //         }else if(user?.role === "companion"){
    //             setCredits(29);
    //             setDollar(dollarsToCents(29));
    //         }
    //     }
    // },[user])

    const handleSubmit = async (event) => {
        event.preventDefault();

        if (!stripe || !elements) {
            return;
        }

        setIsProcessing(true);

        const cardElement = elements.getElement(CardElement);
        const { error, paymentMethod } = await stripe.createPaymentMethod({
            type: "card",
            card: cardElement,
        });

        if (error) {
            setPaymentMessage(error.message);
            setIsProcessing(false);
        } else {
            dispatch(
                purchaseSubscription({
                    paymentData: { amount: dollar, currency: "usd", description: "Purchased Subscription" },
                    user,
                })
            ).then(async (res) => {
                if (res.payload?.clientSecret) {
                    const { clientSecret } = res.payload;

                    const confirmPayment = await stripe.confirmCardPayment(clientSecret, {
                        payment_method: paymentMethod.id,
                    });

                    if (confirmPayment.error) {
                        setPaymentMessage("Payment failed. Please try again.");
                        setIsProcessing(false);
                    } else {
                        setPaymentMessage("Payment successful!");
                        // if(user?.role === "loved-one"){
                        //     setCredits(99);
                        //     setDollar(dollarsToCents(99));
                        // }else if(user?.role === "companion"){
                        //     setCredits(49);
                        //     setDollar(dollarsToCents(49));
                        // }
                        setIsProcessing(false);
                        setTimeout(()=>{
                            window.location.href = '/user/dashboard'
                        },1000)
                    }
                }
            });
        }
    };

    const cardElementOptions = {
        style: {
            base: {
                iconColor: "#c4f0ff",
                color: "#333",
                fontWeight: "500",
                fontFamily: "Roboto, Open Sans, Segoe UI, sans-serif",
                fontSize: "16px",
                fontSmoothing: "antialiased",
                "::placeholder": {
                    color: "#888",
                },
            },
            invalid: {
                iconColor: "#ff3860",
                color: "#ff3860",
            },
        },
        hidePostalCode: true,
    };

    function dollarsToCents(dollars) {
        if (typeof dollars !== "number" || isNaN(dollars)) {
            throw new Error("Input must be a valid number");
        }
        return Math.round(dollars * 100);
    }

    return (
        <>
            <form
                onSubmit={handleSubmit}
                style={{ maxWidth: "100%", margin: "auto" }}
            >
                <div
                    style={{
                        padding: "10px",
                        border: "1px solid #ccc",
                        borderRadius: "6px",
                        marginBottom: "10px",
                    }}
                >
                    <CardElement options={cardElementOptions} />
                </div>

                <div className="btn-div d-flex justify-content-center mt-4">
                    <button className="m-auto" type="submit" disabled={isProcessing}>
                        {isProcessing ? "Processing..." : `Pay $${credits}`}
                    </button>
                </div>
                {paymentMessage && <p>{paymentMessage}</p>}
            </form>
        </>
    );
};

function StripeModal(props) {

    return (
        <div>
            <Modal centered show={props.show} onHide={props.handleClose}>
                <Modal.Header closeButton>
                    <Modal.Title>Enter your Card Details</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Elements stripe={stripePromise}>
                        <CheckoutForm amount={props.amount}/>
                    </Elements>
                </Modal.Body>
            </Modal>
        </div>
    )
}

export default StripeModal