import { createSlice } from "@reduxjs/toolkit";
import { addCaseWithLoading } from "../../helper";
import * as Actions from "./actions";

const initialState = {
  isLoading: false,
  error: null,
  transaction: null,
};

const transactionsSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    addCaseWithLoading(builder, Actions.getTransaction, {
      onCompleted: (state, action) => {
        console.log(action);
        state.isLoading = false;
        state.transaction = action.payload.data;
      },
      onPending: (state) => {
        state.isLoading = true;
        state.error = null;
      },
      onReject: (state, error) => {
        state.isLoading = false;
        state.error = error?.error;
      },
    });
  },
});

export default transactionsSlice.reducer;
